import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SketchfabWrapper from 'sketchfab-api-wrapper'

import translations from 'mappers/translations.mapper'
import { Container } from './ModelFrame.style'
import snOpts from './sneakerIframeOptions.json'
import glOpts from './globeIframeOptions.json'
import glCoOpts from './globeCoverIframeOptions.json'

class ModelFrame extends Component {
  componentDidMount() {
    this.angle = 0
    this.rotationInterval = 100 //ms
    this.cameraEaseDuration = 2 //s
    this.materialNames = []
    this.materialIndex = 0

    const { uid, onClientInit, type = 'sneaker' } = this.props
    const wrapper = new SketchfabWrapper({
      iframeQuery: '#' + type, // e.g. #api-frame
      uid, // sketchfab model id
      options: type === 'sneaker' ? snOpts : type === 'globe' ? glOpts : glCoOpts, // options from here: https://sketchfab.com/developers/viewer/initialization
      useSingleton: false,
    })

    wrapper.init().then(() => {
      this.api = { ...wrapper, ...wrapper.api } // Hack to use native sketchfab api inline with wrapper methords
      if (type === 'sneaker') {
        window.apiSn = this.api // for easy dev testing from browser console
      } else if (type === 'globeCover') {
        window.apiGl = this.api // for easy dev testing from browser console
      }
      if (onClientInit) onClientInit(this.api) // Your custom function to start afer api is ready
    })
  }

  removeClearBox = () => {
    document.getElementById('clearBox').className += ' hidden'
  }

  render() {
    const {
      uid,
      styling,
      showCallToAction,
      type,
      lng,
      isTextSmall,
      showLoading,
      globeImage,
      showGlobeImage,
      pointerEvents,
    } = this.props

    return (
      <Container className={type}>
        {type === 'sneaker' && (
          <React.Fragment>
            <div className="clouds clouds1"></div>
            <div className="clouds clouds2"></div>
            {/* <div className="clouds clouds3"></div> */}
          </React.Fragment>
        )}
        <div
          className={'globeImage' + (!(globeImage && !!showGlobeImage) ? ' hidden' : '')}
          style={{
            backgroundImage: `url(${globeImage})`,
            opacity: globeImage && !!showGlobeImage && !showLoading ? 1 : 0,
          }}
        ></div>
        <iframe
          // style={{ opacity: sketchfabLoaded ? 1 : 0 }}
          title={uid}
          id={type}
          style={{ ...styling, pointerEvents: pointerEvents ? 'all' : 'none' }}
          // height={iframeHeight}
          // width={iframeWidth}
        />
        <a id="loading" className={showLoading ? '' : ' hidden'} href>
          <div className="image"></div>
        </a>
        {type === 'sneaker' && (
          <a
            id="clearBox"
            className={showCallToAction ? '' : 'hidden'}
            href
            onClick={(e) => {
              e.preventDefault()
              this.removeClearBox()
            }}
          >
            <div className="image"></div>
            <div className={'text' + (isTextSmall ? ' small' : '')}>{translations[lng].cta}</div>
          </a>
        )}
      </Container>
    )
  }
}

ModelFrame.propTypes = {}

export default ModelFrame
