import React from 'react'
import styled from 'styled-components'

import MainWrapper from 'components/MainWrapper'

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .colorTitle,
  .links a,
  .text {
    font-family: 'Styrene Medium', Helvetica, Arial, Sans-serif;
  }

  @media (min-width: 850px) {
    .overflowingControls {
      background: rgba(112, 112, 112, 0.3);
      background: linear-gradient(
        to bottom,
        rgba(112, 112, 112, 0.3) 0%,
        rgba(112, 112, 112, 0) 15%,
        rgba(112, 112, 112, 0) 85%,
        rgba(112, 112, 112, 0.8) 100%
      );
    }
    .onlyMobile {
      display: none !important;
    }
    .text {
      font-size: 24px;
      line-height: 32px;
      &.small {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .links a {
      font-size: 14px;
      line-height: 22px;
      margin: 0 12px;
      border-bottom: 1px solid #fff;
    }
    .colorTitle {
      font-size: 12px;
      line-height: 22px;
    }
    .footing {
      flex-direction: row;
      justify-content: space-between;
      padding: 36px;
      box-sizing: border-box;
      height: 27px;
      .homeAnnotation {
        height: 20px;
        width: 20px;
        margin: 0px 12px;
      }
      .colorsWrapper {
        .colorButton {
          margin-right: 18px;
          &.active {
            &:after {
              box-shadow: #fff 0px 0px 0px 2px;
            }
          }
        }
      }
    }
  }
  /* mobile */
  @media (max-width: 850px) {
    .overflowingControls {
      background: rgba(112, 112, 112, 0.3);
      background: linear-gradient(
        to bottom,
        rgba(112, 112, 112, 0.3) 0%,
        rgba(112, 112, 112, 0) 15%,
        rgba(112, 112, 112, 0) 70%,
        rgba(112, 112, 112, 0.7) 100%
      );
    }
    #clearBox {
      padding-bottom: 48px;
      box-sizing: border-box;
    }
    .onlyDesktop {
      display: none !important;
    }
    .text {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      padding: 0 20px;
    }
    .links {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      padding: 9px;
      opacity: 0;
      a {
        font-size: 12px;
        line-height: 30px;
        margin: 0 auto;
        border-bottom: none;
        width: 100%;
        text-align: center;
      }
    }
    /* not visible */
    .colorTitle {
      font-size: 12px;
      line-height: 20px;
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
      &.hidden {
        opacity: 0;
      }
    }
    .links
    /* , .colorTitle  */
 {
      transition: opacity 0.4s 0.4s ease-in-out, visibility 0s 0.8s ease-in-out;
    }
    .footing {
      flex-direction: column-reverse;
      justify-content: flex-end;
      position: relative;
      margin-bottom: 0;
      transition: all 0.2s ease-in-out;
      .homeAnnotation {
        height: 27px;
        width: 27px;
      }
      &.hidden {
        margin-bottom: -48px;
      }
      & > * {
        justify-content: center;
      }
      &:before {
        content: '';
        position: absolute;
        height: 48px;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: #fff;
        border-bottom: 1px solid #dadada;
      }
      .right {
        position: relative;
        height: 30px;
        margin-bottom: 15px;
        & > * {
          position: absolute;
        }
      }
      .colorsWrapper {
        width: 100%;
        margin: auto;
        height: 48px;
        justify-content: space-around;

        .colorButton.active {
          &:after {
            box-shadow: #ddd 0px 0px 0px 2px;
          }
        }
        .circle {
          padding: 5px;
        }
      }
    }
  }
`

function App() {
  return (
    <AppContainer>
      <MainWrapper />
    </AppContainer>
  )
}

export default App
