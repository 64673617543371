const translations = {
  en: {
    cta: 'Drag to view 360°',
    reset: 'Start again',
    colourway: 'Choose Your Colourway',
    c1: 'Bridle Brown',
    c2: 'Black/Crystal',
    c3: 'White/Red',
    c4: 'Grey/Red',
    c5: 'Archive Beige',
    c6: 'Black',
    c7: 'Red',
    woman: 'Shop women',
    man: 'Shop men',
  },
  fr: {
    cta: 'Faites glisser pour une vue à 360°',
    reset: 'Recommencer',
    colourway: 'Choisissez votre coloris',
    c1: 'Brun bride',
    c2: 'Noir/Cristal',
    c3: 'Blanc/Rouge',
    c4: 'Gris/Rouge',
    c5: "Beige d'archive",
    c6: 'Noir',
    c7: 'Rouge',
    woman: 'Femme',
    man: 'Homme',
  },
  de: {
    cta: 'Für eine 360-Grad-Ansicht ziehen',
    reset: 'Erneut beginnen',
    colourway: 'Farben auswählen',
    c1: 'Lederbraun',
    c2: 'Schwarz/Kristall',
    c3: 'Weiß/Rot',
    c4: 'Grau/Rot',
    c5: 'Vintage-Beige',
    c6: 'Schwarz',
    c7: 'Rot',
    woman: 'Designs für Damen',
    man: 'Designs für Herren',
  },
  it: {
    cta: 'Trascina per vederla a 360°',
    reset: 'Ricomincia',
    colourway: 'Scegli la combinazione di colori',
    c1: 'Marrone redini',
    c2: 'Nero/cristallo',
    c3: 'Bianco/rosso',
    c4: 'Grigio/rosso',
    c5: 'Beige archivio',
    c6: 'Nero',
    c7: 'Rosso',
    woman: 'Donna',
    man: 'Uomo',
  },
  ja: {
    cta: 'ドラッグして360°ビューを見る',
    reset: 'もう一度試す',
    colourway: 'カラーを選択',
    c1: 'ブライドルブラウン',
    c2: 'ブラック／クリスタル',
    c3: 'ホワイト／レッド',
    c4: 'グレー／レッド',
    c5: 'アーカイブベージュ',
    c6: 'ブラック',
    c7: 'レッド',
    woman: 'ウィメンズ',
    man: 'メンズ',
  },
  zf: {
    // CHT
    cta: '拖曳以旋轉檢視',
    reset: '重新開始',
    colourway: '選擇喜愛的配色',
    c1: '馬勒棕',
    c2: '黑色／水晶',
    c3: '白色／紅色',
    c4: '灰色／紅色',
    c5: '典藏米色',
    c6: '黑色',
    c7: '紅色',
    woman: '選購女款',
    man: '選購男款',
  },
  zh: {
    // CHS
    cta: '拖动以 360° 浏览',
    reset: '重新开始',
    colourway: '选择喜爱的配色',
    c1: '马勒棕',
    c2: '黑色 / 人造水晶',
    c3: '白色 / 红色',
    c4: '灰色 / 红色',
    c5: '典藏米色',
    c6: '黑色',
    c7: '红色',
    woman: '购买女款',
    man: '购买男款',
  },
  ko: {
    cta: '드래그하여 360° 이미지를 살펴보세요',
    reset: '다시 시작',
    colourway: '컬러 선택하기',
    c1: '브라이들 브라운',
    c2: '블랙/크리스털',
    c3: '화이트/레드',
    c4: '그레이/레드',
    c5: '아카이브 베이지',
    c6: '블랙',
    c7: '레드',
    woman: '여성 | 구매하기',
    man: '남성 | 구매하기',
  },
  es: {
    cta: 'Arrastrar para girar 360°',
    reset: 'Volver a empezar',
    colourway: 'Elige un color',
    c1: 'Marrón ecuestre',
    c2: 'Negro/Cristal',
    c3: 'Blanco/Rojo',
    c4: 'Gris/Rojo',
    c5: 'Beige vintage',
    c6: 'Negro',
    c7: 'Rojo',
    woman: 'Mujer',
    man: 'Hombre',
  },
  ru: {
    cta: 'Поверните для панорамного просмотра',
    reset: 'Начать заново',
    colourway: 'Выберите расцветку',
    c1: 'Темно-коричневый',
    c2: 'Черный / Кристальный',
    c3: 'Белый / Красный',
    c4: 'Серый / Красный',
    c5: 'Винтажный бежевый',
    c6: 'Черный',
    c7: 'Красный',
    woman: 'Для женщин',
    man: 'Для мужчин',
  },
  br: {
    cta: 'Arraste para visualizar 360°',
    reset: 'Iniciar novamente',
    colourway: 'Escolha seu esquema de cores',
    c1: 'Castanho couro',
    c2: 'Preto/Cristal',
    c3: 'Branco/Vermelho',
    c4: 'Cinza/Vermelho',
    c5: 'Bege vintage',
    c6: 'Preto',
    c7: 'Vermelho',
    woman: 'Coleção feminina',
    man: 'Coleção masculina',
  },
}

export default translations
