const countries = {
  au: 'au',
  at: 'at',
  be: 'be',
  bg: 'bg',
  ca: 'ca',
  cn: 'cn',
  cy: 'cy',
  cz: 'cz',
  dk: 'dk',
  ee: 'ee',
  fi: 'fi',
  fr: 'fr',
  de: 'de',
  gr: 'gr',
  hk: 'hk',
  hu: 'hu',
  ie: 'ie',
  it: 'it',
  jp: 'jp',
  kw: 'kw',
  lv: 'lv',
  lt: 'lt',
  lu: 'lu',
  mo: 'mo',
  my: 'my',
  mt: 'mt',
  nl: 'nl',
  pl: 'pl',
  pt: 'pt',
  qa: 'qa',
  ro: 'ro',
  ru: 'ru',
  sg: 'sg',
  sk: 'sk',
  si: 'si',
  kr: 'kr',
  es: 'es',
  se: 'se',
  ch: 'ch',
  tw: 'tw',
  tr: 'tr',
  ae: 'ae',
  uk: 'uk',
  gb: 'uk',
  us: 'us',
}

export default countries
