import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  .clouds {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
    opacity: 0.9;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &,
  * {
    transition: all 0.4s ease-in-out, opacity 0.5s ease-in-out !important;
  }
  &.sneaker {
    /* background-image: url(${require('./sky/sky.png')}); */
    background-color: #a7bee3;
   .clouds1 {
      background-image: url(${require('./sky/clouds_01.png')});
    animation: opacityChange 5s ease-in-out infinite;
    }
    .clouds2 {
      background-image: url(${require('./sky/clouds_02.png')});
    animation: opacityChange 5s 2.5s ease-in-out infinite;
    }
    /* .clouds3 {
      background-image: url(${require('./sky/clouds_03.png')});
    animation: opacityChange 5s 3s ease-in-out infinite;
    } */
  }
  &.globeCover {
    pointer-events: none;
  }
  &,
  iframe,
  #clearBox,
  #loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }
  #loading {
    pointer-events: none;
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    flex-direction: column;
    .image {
      height: 200px;
      width: 200px;
      /* border: 2px solid #fff; */
      /* transform: rotate(45deg); */
      background-image: url(${require('./loader.gif')});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      animation-iteration-count: infinite;
    }
  }
.globeImage {
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: 100% 100%;    
    top: 0;
    left: 0;
    transition: opacity 0.3s ease-in-out !important;
    user-select: none;
  }
  #clearBox {
    pointer-events: none;
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    flex-direction: column;
    .image {
      height: 74px;
      width: 74px;
      /* border: 2px solid #fff; */
      /* transform: rotate(45deg); */
      background-image: url(${require('./cube.gif')});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      animation-iteration-count: infinite;
    }
    .text {
      text-transform: uppercase;
      color: #fff;
    }
  }

  .hidden {
    opacity: 0 !important;
  }

  @keyframes opacityChange {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`

export const style = {}

export default style
