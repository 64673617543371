import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  .overflowingControls {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
    overflow: hidden;
    &,
    *,
    *:before {
      transition: all 0.4s ease-in-out;
    }
    .footing {
      display: flex;
      width: 100%;
      .colorTitle {
        color: #fff;
        text-transform: uppercase;
      }
      .colorsWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        pointer-events: all;
        z-index: 2;
        .colorButton {
          pointer-events: all;
          cursor: pointer;
          height: 20px;
          width: 20px;
          box-sizing: border-box;
          border-radius: 100%;
          border: 2px solid #f1f2f4;
          position: relative;
          z-index: 1;
          .roundWrap {
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 100%;
            border-radius: 100%;
            overflow: hidden;
            .color {
              flex: 1;
              height: 100%;
              width: 100%;
            }
          }
          &:before {
            border-radius: 100%;
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
          }
          &:after {
            border-radius: 100%;
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            box-shadow: transparent 0px 0px 0px 2px;
            transition: box-shadow 0.2s ease-in-out;
          }
        }
        .circle {
          border: 2px solid #dddddd;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 20px;
          width: 20px;
          border-radius: 100%;
          margin-left: -5px;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .links {
          .split {
            height: 30px;
            width: 1px;
            background-color: #dadada;
          }
          a {
            color: #fff;
            pointer-events: all;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 0.4s ease-in-out;
            &:hover {
              opacity: 0.8;
            }
            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }
          }
          &.disabled {
            opacity: 0;
          }
        }
        .homeAnnotation {
          pointer-events: all;
          cursor: pointer;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1;
          z-index: 3;
          transition: background-image 0s !important;
          &:not(.isZoom) {
            background-image: url(${require('./home.png')});
          }
          &.isZoomIn {
            background-image: url(${require('./+icon.svg')});
          }
          &.isZoomOut {
            background-image: url(${require('./-icon.svg')});
          }
          /* MAKE IT APPEAR SLOWLY */
          &:hover {
            opacity: 1 !important;
          }
          &.hidden {
            /* display: none; */
            visibility: hidden;
          }
        }
      }
    }
    .hidden {
      visibility: hidden;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    99% {
      opacity: 0;
    }
  }

  .blink {
    animation: blink ease-in-out 2s 0s 1.5 forwards;
  }
`

export const style = {}

export default style
