import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ModelFrame from 'components/ModelFrame'
import colorSchemes from 'mappers/color-schemes.mapper'
import translations from 'mappers/translations.mapper'
import countries from 'mappers/countries.mapper'
import { changeSneaker, updateMaterials } from 'helpers/color-schemes.helper'
import { moveCameraBackToSphere, pollCamera } from 'helpers/camera.helper'
import { style, Container } from './MainWrapper.style'

const MOBILE_SCREEN_MAX = 850
const MOBILE_SCREEN_MIN = 400

class MainWrapper extends Component {
  constructor() {
    super()

    const parameters = new URLSearchParams(window.location.search)
    let lng = 'en'
    let country = 'uk'
    let isTextSmall = false
    if (parameters.has('lang')) {
      lng = parameters.get('lang').toLowerCase()
    }
    if (parameters.has('country')) {
      country = countries[parameters.get('country').toLowerCase()] || 'uk'
    }
    if (parameters.has('tmp_small_text')) {
      isTextSmall = parameters.get('tmp_small_text') === 'true'
    }

    this.state = {
      // activeScheme: colorSchemes[0],
      activeScheme: undefined,
      isLoaded: false,
      isReady: false,
      isSneakerReady: false,
      isGlobeReady: false,
      isInteractive: false,
      isCameraAway: false,
      hoveredScheme: null,
      startedInteraction: false,
      isColorSelected: false,
      viewAnnotation: this.getConfigAnnotationName(),
      lng,
      country,
      isTextSmall,
      lockHome: false,
      globeImage: null,
      showGlobeImage: true,
      pointerEvents: true,
    }
  }

  componentDidUpdate(_, prevState) {
    const { isCameraAway: wasCameraAway, startedAnimation: wasStartedAnimation } = prevState
    const { isCameraAway, startedInteraction, startedAnimation } = this.state

    if (!wasStartedAnimation && startedAnimation && !!this.apiSn) {
      // hide globe
      this.apiSn.node_show('globe')
      setTimeout(() => this.setState({ showGlobeImage: false }), 200)
    }
    if (wasStartedAnimation && !startedAnimation && !!this.apiSn) {
      // show globe
      setTimeout(() => this.apiSn.node_hide('globe'), 500)
      this.setState({ showGlobeImage: true })
    }
    if (!startedInteraction && (isCameraAway && !wasCameraAway)) {
      this.setState({
        startedInteraction: true,
      })
    }
  }

  getConfigAnnotationName = () =>
    window.innerWidth < MOBILE_SCREEN_MAX
      ? window.innerWidth < MOBILE_SCREEN_MIN
        ? 'm_config'
        : 'm_config_wide'
      : 'd_config'
  getZoomAnnotationName = () =>
    window.innerWidth < MOBILE_SCREEN_MAX ? (window.innerWidth < MOBILE_SCREEN_MIN ? 'd_zoom' : 'd_zoom') : 'd_zoom'

  makeScreenshot = (cb) => {
    let id = window.setTimeout(function() {}, 0)
    while (id--) {
      window.clearTimeout(id) // will do nothing if no timeout with id is present
    }

    this.setState({
      showGlobeImage: false,
      isReady: false,
      pointerEvents: false,
    })

    this.apiSn.setPostProcessing({
      // dofFocusPoint,
      dofFocusPoint: [0, 0, 0],
      dofBlurFar: 0.4,
      dofBlurNear: 0.4,
      dofEnable: true,
    })
    // this.apiSn.getPostProcessing(({ dofFocusPoint }) => {
    //   this.dofFocusPoint = dofFocusPoint

    // })

    let windowWidth = 0,
      windowHeight = 0
    setTimeout(() => {
      if (typeof window.innerWidth == 'number') {
        //Non-IE
        windowWidth = window.innerWidth
        windowHeight = window.innerHeight
      } else if (
        document.documentElement &&
        (document.documentElement.clientWidth || document.documentElement.clientHeight)
      ) {
        //IE 6+ in 'standards compliant mode'
        windowWidth = document.documentElement.clientWidth
        windowHeight = document.documentElement.clientHeight
      } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        //IE 4 compatible
        windowWidth = document.body.clientWidth
        windowHeight = document.body.clientHeight
      }

      // console.log(windowWidth, windowHeight)
      this.apiSn.pause()
      this.apiSn.seekTo(0)
      this.apiSn.node_show('globe')
      this.apiSn.node_hide('shoe')
      this.apiSn.setCameraLookAt(
        this.apiSn._getAnnotationByName(this.getConfigAnnotationName()).eye,
        this.apiSn._getAnnotationByName(this.getConfigAnnotationName()).target,
        0
      )
    }, 500)
    setTimeout(
      () =>
        this.apiSn.getScreenShot(windowWidth, windowHeight, 'image/png', (err, res) => {
          // console.log(res)
          this.apiSn.node_show('shoe')
          this.apiSn.node_hide('globe')
          if (!err) {
            this.setState({
              globeImage: res,
              showGlobeImage: true,
            })
            setTimeout(
              () =>
                this.setState(
                  {
                    isReady: true,
                    pointerEvents: true,
                  },
                  () => {
                    this.apiSn.setPostProcessing({
                      dofEnable: false,
                    })
                  }
                ),
              200
            )
          }
          if (cb) cb()
        }),
      2600
    )
  }

  onSneakerInit = (api) => {
    this.apiSn = api

    // api.getPostProcessing(({ dofFocusPoint }) => {
    //   this.dofFocusPoint = dofFocusPoint

    //   api.setPostProcessing({
    //     dofFocusPoint,
    //     dofBlurFar: 0.4,
    //     dofBlurNear: 0.4,
    //     // dofEnable: true,
    //   })
    // })

    const activeScheme = colorSchemes[0]
    let screenshotCB
    api.setCycleMode('one')

    api.setCurrentAnimationByUID(api.animations.filter((a) => a[1] === 'configure')[0][0], () => {
      clearTimeout(screenshotCB)
      screenshotCB = setTimeout(
        () =>
          // here make the screenshot of the default globe and ring bg
          this.makeScreenshot(() => {
            api.seekTo(0.5)
            api.play()

            setTimeout(() => {
              this.setState({
                isSneakerReady: true,
                activeScheme,
                isReady: true,
                isLoaded: true,
              })
              this.onModelsInit()
            }, 150)
            // start main scene transformations
            setTimeout(() => {
              api.pause()
              api.seekTo(0)
            }, 700)

            let resizeId
            window.addEventListener('resize', () => {
              clearTimeout(resizeId)
              resizeId = setTimeout(this.makeScreenshot, 500)
            })
          }),
        1200
      )
    })

    // if (window.innerWidth > MOBILE_SCREEN_MAX) {
    api.addEventListener('click', (node) => {
      if (this.prevNode) {
        const { viewAnnotation } = this.state
        const zoomAnnot = this.getZoomAnnotationName()
        const configAnnot = this.getConfigAnnotationName()
        // const { instanceID: prevID } = this.prevNode
        // const { instanceID } = node

        // if (prevID === instanceID) {
        api.gotoAnnotation(api._getAnnotationByName(viewAnnotation === zoomAnnot ? configAnnot : zoomAnnot).index)
        this.setState({
          viewAnnotation: viewAnnotation === zoomAnnot ? configAnnot : zoomAnnot,
        })
        this.prevNode = null
      }
      // }
      this.prevNode = true

      setTimeout(() => (this.prevNode = null), 300)
    })
    // }

    api.setCameraLookAt(
      api._getAnnotationByName(this.getConfigAnnotationName()).eye,
      api._getAnnotationByName(this.getConfigAnnotationName()).target,
      0
    )

    this.setState({
      isSneakerReady: true,
      activeScheme,
    })
    setTimeout(() => updateMaterials({ scheme: activeScheme, apiSn: api }), 50)
  }

  onGlobeInit = (api) => {
    this.apiGl = api
    api.getPostProcessing(({ dofFocusPoint }) => (this.dofFocusPoint = dofFocusPoint))
    // api.setCycleMode('one')
    api.setCurrentAnimationByUID(api.animations.filter((a) => a[1] === 'configure')[0][0], () =>
      setTimeout(() => {
        api.seekTo(0.5)
        this.setState({
          isGlobeReady: true,
        })
      }, 100)
    )

    // api.pause()
    // api.seekTo(0.5)

    api.setCameraLookAt(
      api._getAnnotationByName(this.getConfigAnnotationName()).eye,
      api._getAnnotationByName(this.getConfigAnnotationName()).target,
      0
    )
  }

  onModelsInit = () => {
    setTimeout(() => {
      this.setState({ isInteractive: true })
      this.apiSn.addEventListener('camerastart', () => {
        // show reset button and hide globe
        const { isCameraAway } = this.state
        if (!isCameraAway)
          this.setState({
            isCameraAway: true,
          })
      })
      this.apiSn.addEventListener('camerastop', () => {
        this.apiSn.getCameraLookAt((err, points) => {
          const { position: currentPosition } = points
          const annotation = this.apiSn.annotations.filter((a) => a.name === this.getConfigAnnotationName())

          if (annotation && annotation[0]) {
            const { eye } = annotation[0]

            // if (eye === eye of annotation) hide reset button
            if (currentPosition.map((v) => Math.ceil(v)).every((e) => eye.map((v) => Math.ceil(v)).includes(e))) {
              this.setState(
                {
                  isCameraAway: false,
                  doRotateAnimation: false,
                }
                // () =>
                //   doRotateAnimation &&
                //   changeSneaker({
                //     scheme: activeScheme,
                //     apiSn: this.apiSn,
                //     apiGl: this.apiGl,
                //   })
              )
            }
          }
        })
      })
    }, 800)
  }

  setColorScheme = (newScheme) => {
    const { isCameraAway, pointerEvents } = this.state
    if (!pointerEvents) return
    setTimeout(() => {
      this.setState({
        startedAnimation: true,
      })
      this.apiSn.setPostProcessing({
        dofEnable: true,
      })
    }, 400)
    this.apiSn.setCameraLookAt(
      this.apiSn._getAnnotationByName(this.getConfigAnnotationName()).eye,
      this.apiSn._getAnnotationByName(this.getConfigAnnotationName()).target,
      0.15
    )
    setTimeout(
      () =>
        this.setState(
          {
            activeScheme: newScheme,
            doRotateAnimation: isCameraAway ? true : false,
            startedInteraction: true,
            isColorSelected: true,
            viewAnnotation: this.getConfigAnnotationName(),
            // startedAnimation: true,
            pointerEvents: false,
            lockHome: true,
          },
          () => {
            if (this.apiSn) {
              this.apiSn.setCameraLookAt(
                this.apiSn._getAnnotationByName(this.getConfigAnnotationName()).eye,
                this.apiSn._getAnnotationByName(this.getConfigAnnotationName()).target,
                0
              )
              setTimeout(() => {
                this.setState({}, () =>
                  changeSneaker({
                    scheme: newScheme,
                    apiSn: this.apiSn,
                    // apiGl: this.apiGl,
                  }).then(() => {
                    this.setState({
                      startedAnimation: false,
                    })
                    setTimeout(
                      () =>
                        this.setState(
                          {
                            lockHome: false,
                            pointerEvents: true,
                          },
                          () =>
                            this.apiSn.setPostProcessing({
                              dofEnable: false,
                            })
                        ),
                      500
                    )
                  })
                )
              }, 200)
            }
            // if (isCameraAway) {
            //   // if (this.api) this.this.apiGl.gotoAnnotation(this.this.apiGl._getAnnotationByName(this.getConfigAnnotationName()).index)
            //   if (this.apiSn)
            //     this.apiSn.setCameraLookAt(
            //       this.apiSn._getAnnotationByName(this.getConfigAnnotationName()).eye,
            //       this.apiSn._getAnnotationByName(this.getConfigAnnotationName()).target,
            //       0.8
            //     )
            // } else {
            //   if (this.apiSn)
            //     changeSneaker({
            //       scheme: newScheme,
            //       apiSn: this.apiSn,
            //       // apiGl: this.apiGl,
            //     })
            // }
          }
        ),
      250
    )
  }

  render() {
    const {
      activeScheme,
      isLoaded,
      isReady,
      isGlobeReady,
      isInteractive,
      isCameraAway,
      hoveredScheme,
      startedInteraction,
      isColorSelected,
      lng,
      viewAnnotation,
      country,
      isTextSmall,
      lockHome,
      startedAnimation,
      globeImage,
      showGlobeImage,
      pointerEvents,
    } = this.state

    const zoomAnnot = this.getZoomAnnotationName()
    const configAnnot = this.getConfigAnnotationName()

    return (
      <Container>
        {/* <ModelFrame
          uid="d7e109631b1e45779eb727564ba5a0d2"
          onClientInit={this.onGlobeInit}
          styling={{ opacity: isReady ? 1 : 0, pointerEvents: 'none' }}
          type="globe"
          showLoading={!isReady}
          lng={lng}
        />
        <ModelFrame
          uid="0a3d1c1dd5d543bda25dea1cd100bd28"
          // uid="6c4f38ad320045dbaee9334b22308d4f"
          onClientInit={this.onSneakerInit}
          styling={{ opacity: isReady ? 1 : 0 }}
          showCallToAction={!startedInteraction && isInteractive}
          type="sneaker"
          lng={lng}
          isTextSmall={isTextSmall}
        />
        {window.innerWidth > MOBILE_SCREEN_MAX && (
          <ModelFrame
            uid="ede5a0a8ac8542c283122c4612bfbcd2"
            // onClientInit={this.onSneakerInit}
            // styling={{ opacity: 1 }}
            styling={{ opacity: isReady && startedAnimation ? 1 : 0 }}
            showCallToAction={!startedInteraction && isInteractive}
            type="globeCover"
            lng={lng}
          />
        )} */}
        <ModelFrame
          uid="e29f94734aae4392b68a21d976caab93"
          onClientInit={this.onSneakerInit}
          styling={{ opacity: isReady ? 1 : 0 }}
          showCallToAction={!startedInteraction && isInteractive}
          type="sneaker"
          lng={lng}
          isTextSmall={isTextSmall}
          showLoading={!isReady}
          globeImage={globeImage}
          showGlobeImage={!!showGlobeImage}
          pointerEvents={pointerEvents}
        />

        <div className="overflowingControls">
          <div className={'footing' + (!isInteractive || !isReady ? ' hidden' : '')}>
            <div className="colorsWrapper onlyDesktop" style={{ opacity: isLoaded && isInteractive ? 1 : 0 }}>
              {colorSchemes
                .filter((s) => s.active)
                .map((scheme) => (
                  <div
                    key={scheme.name}
                    onClick={() => this.setColorScheme(scheme)}
                    onMouseEnter={() => this.setState({ hoveredScheme: scheme })}
                    onMouseLeave={() => this.setState({ hoveredScheme: null })}
                    className={
                      'colorButton' +
                      ((hoveredScheme || activeScheme) && (hoveredScheme || activeScheme).name === scheme.name
                        ? ' active'
                        : '')
                    }
                  >
                    <div className="roundWrap">
                      {scheme.buttonColors.map((c) => (
                        <div className="color" style={{ backgroundColor: c }}></div>
                      ))}
                    </div>
                  </div>
                ))}
              {/* <div
                className="circle"
                style={{
                  left:
                    (activeScheme || hoveredScheme) &&
                    colorSchemes.filter((s) => s.active).map((c) => c.name).indexOf((hoveredScheme || {}).name || activeScheme.name) * 38,
                }}
              ></div> */}
              <div className="colorTitle">
                {(activeScheme || hoveredScheme) && isColorSelected
                  ? translations[lng][(hoveredScheme || {}).code || activeScheme.code]
                  : translations[lng].colourway}
              </div>
            </div>

            {/* Dublicated for mobile */}
            <div className="colorsWrapper onlyMobile">
              {colorSchemes
                .filter((s) => s.active)
                .map((scheme) => (
                  <div
                    key={scheme.name}
                    onClick={() => this.setColorScheme(scheme)}
                    onMouseEnter={() => this.setState({ hoveredScheme: scheme })}
                    onMouseLeave={() => this.setState({ hoveredScheme: null })}
                    className={
                      'colorButton' +
                      ((hoveredScheme || activeScheme) && (hoveredScheme || activeScheme).name === scheme.name
                        ? ' active'
                        : '')
                    }
                  >
                    <div className="roundWrap">
                      {scheme.buttonColors.map((c) => (
                        <div className="color" style={{ backgroundColor: c }}></div>
                      ))}
                    </div>
                  </div>
                ))}
              {/* <div
                className="circle"
                style={{
                  left: `calc(${(((activeScheme || hoveredScheme) &&
                    colorSchemes.filter((s) => s.active).map((c) => c.name).indexOf((hoveredScheme || {}).name || activeScheme.name) * 2 + 1) *
                    100) /
                    14}% - 12px)`,
                }}
              ></div> */}
            </div>
            <div className="right onlyDesktop">
              <div
                className={'homeAnnotation isZoomIn'}
                onClick={() => {
                  if (this.apiSn) {
                    this.apiSn.gotoAnnotation(this.apiSn._getAnnotationByName(zoomAnnot).index)
                    this.setState({
                      viewAnnotation: zoomAnnot,
                    })
                  }
                }}
              ></div>
              <div
                className={'homeAnnotation isZoomOut'}
                onClick={() => {
                  if (this.apiSn) {
                    this.apiSn.gotoAnnotation(this.apiSn._getAnnotationByName(configAnnot).index)
                    this.setState({
                      viewAnnotation: configAnnot,
                    })
                  }
                }}
              ></div>
              {/* {viewAnnotation === zoomAnnot ? (
                <div
                  className={'homeAnnotation isZoomOut'}
                  onClick={() => {
                    if (this.apiSn) {
                      this.apiSn.gotoAnnotation(
                        this.apiSn._getAnnotationByName(viewAnnotation === zoomAnnot ? configAnnot : zoomAnnot).index
                      )
                      this.setState({
                        viewAnnotation: viewAnnotation === zoomAnnot ? configAnnot : zoomAnnot,
                      })
                    }
                  }}
                ></div>
              ) : (
                <div
                  className={'homeAnnotation isZoomIn'}
                  onClick={() => {
                    if (this.apiSn) {
                      this.apiSn.gotoAnnotation(
                        this.apiSn._getAnnotationByName(viewAnnotation === zoomAnnot ? configAnnot : zoomAnnot).index
                      )
                      this.setState({
                        viewAnnotation: viewAnnotation === zoomAnnot ? configAnnot : zoomAnnot,
                      })
                    }
                  }}
                ></div>
              )} */}
              <div className={'links' + (activeScheme && isInteractive ? '' : ' hidden')}>
                <a
                  href={
                    activeScheme &&
                    activeScheme.availability.woman &&
                    `https://${country}.burberry.com/${activeScheme.availability.woman}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={activeScheme && activeScheme.availability.woman ? '' : 'disabled'}
                >
                  {translations[lng].woman}
                </a>
                <a
                  href={
                    activeScheme &&
                    activeScheme.availability.man &&
                    `https://${country}.burberry.com/${activeScheme.availability.man}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={activeScheme && activeScheme.availability.man ? '' : 'disabled'}
                >
                  {translations[lng].man}
                </a>
              </div>
            </div>

            {/* dublicated for mobile */}
            <div className="right onlyMobile">
              {/* <div
                className={'colorTitle' + (!isCameraAway && isColorSelected ? '' : ' hidden')}
                style={{ opacity: isColorSelected ? 0 : 1 }}
              > */}
              <div
                className={'colorTitle' + (!isCameraAway && isInteractive && isReady ? '' : ' hidden')}
                style={{ opacity: isInteractive && !isColorSelected ? 1 : 0 }}
              >
                {translations[lng].colourway}
              </div>
              {isCameraAway && !lockHome && (
                <div
                  className={
                    'homeAnnotation blink'
                    // + (!isCameraAway ? ' hidden' : '')
                  }
                  onClick={() =>
                    this.apiSn &&
                    this.apiSn.setCameraLookAt(
                      this.apiSn._getAnnotationByName(configAnnot).eye,
                      this.apiSn._getAnnotationByName(configAnnot).target,
                      0.5
                    )
                  }
                ></div>
              )}
              <div
                className={
                  'links'
                  // + (activeScheme && isInteractive && isColorSelected && !isCameraAway ? '' : ' hidden')
                }
                style={{
                  opacity: isColorSelected
                    ? // && !isCameraAway
                      1
                    : 0,
                }}
              >
                <a
                  href={
                    activeScheme &&
                    activeScheme.availability.woman &&
                    `https://${country}.burberry.com/${activeScheme.availability.woman}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={activeScheme && activeScheme.availability.woman ? '' : 'disabled'}
                >
                  {translations[lng].woman}
                </a>
                {(!isCameraAway || lockHome) && <div className="split"></div>}
                <a
                  href={
                    activeScheme &&
                    activeScheme.availability.man &&
                    `https://${country}.burberry.com/${activeScheme.availability.man}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={activeScheme && activeScheme.availability.man ? '' : 'disabled'}
                >
                  {translations[lng].man}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

MainWrapper.propTypes = {}

export default MainWrapper
