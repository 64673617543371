const schemes = [
  {
    name: 'Bridle Brown',
    active: true,
    code: 'c1',
    materialsReplacement: {
      ribbon: 'fake_mat1',
      rubber_top: 'black_simple_rubber',
      rubber_cap: 'black_cap_rubber',
      rubber_rombes: 'black_rombes_rubber',
      rubber_rough: 'black_noise_rubber',
      rubber_stripes: 'black_lines_rubber',
      sole: 'black_simple_rubber',
      logo: 'logo_bw',
      logo2: 'logo_black',
      stitches: 'black_stitches',
      stitches2: 'black_stitches',
      strap1: 'black_strap',
      strap2: 'white_stripe',
      strap3: 'black_strap',
      strap4: 'white_stripe',
      strap5: 'red_stripe',
      strap6: 'black_strap_gloss',
      inside: 'black_satin',
      laces: 'black_laces_simple',
      tongue: 'cloth_b_pattern',
      sides_01: 'cloth_b_pattern',
      sides_02: 'cloth_b_pattern',
      main_front: 'cloth_b_pattern',
      main_back: 'cloth_b_pattern',
      back: 'cloth_b_pattern',
    },
    availability: {
      man: 'prod-80217781',
      woman: 'prod-80206781',
    },
    configuration: {
      nodes: {
        gems: false,
        ribbon_g: true,
      },
      materials: {
        rubbers: '#242424',
        sole: '#242424',
        stitches: '#242424',
        strap1: '#242424',
        strap2: '#a6a6a6',
        strap5: '#842d2b',
        strap6: '#242424',
        inside: '#242424',
        laces: {
          color: '#242424',
          texture: undefined,
        },
        logo: {
          color: '#242424',
          texture: undefined,
        },
        strap3: {
          AlbedoPBR: {
            color: '#242424',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        strap4: {
          AlbedoPBR: {
            color: '#a6a6a6',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        tongue: {
          AlbedoPBR: {
            texCoordUnit: 0,
            texture: 'b_pattern.jpg',
            color: undefined,
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        sides_01: {
          AlbedoPBR: {
            texCoordUnit: 0,
            texture: 'b_pattern.jpg',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 1,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        sides_02: {
          AlbedoPBR: {
            texCoordUnit: 0,
            texture: 'b_pattern.jpg',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        main_front: {
          AlbedoPBR: {
            texCoordUnit: 0,
            texture: 'b_pattern.jpg',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        main_back: {
          AlbedoPBR: {
            texCoordUnit: 0,
            texture: 'b_pattern.jpg',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        back: {
          AlbedoPBR: {
            texCoordUnit: 0,
            texture: 'b_pattern.jpg',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
      },
    },
    buttonColors: ['#3E3124'],
  },
  {
    name: 'Grey/Red',
    active: true,
    code: 'c4',
    materialsReplacement: {
      ribbon: 'fake_mat2',
      rubber_top: 'black_simple_rubber',
      rubber_cap: 'black_cap_rubber',
      rubber_rombes: 'black_rombes_rubber',
      rubber_rough: 'black_noise_rubber',
      rubber_stripes: 'black_lines_rubber',
      sole: 'black_simple_rubber',
      logo: 'logo_bw',
      logo2: 'logo_black',
      stitches: 'grey_stitches',
      stitches2: 'grey_stitches',
      strap1: 'grey_strap',
      strap2: 'grey_stripe',
      strap3: 'blue_stripe',
      strap4: 'blue_stripe',
      strap5: 'grey_stripe',
      strap6: 'grey_strap_gloss',
      inside: 'red_satin',
      laces: 'grey_laces',
      tongue: 'grey_check_cloth',
      sides_01: 'grey_suede',
      sides_02: 'grey_cloth',
      main_front: 'grey_check_cloth',
      main_back: 'red_satin',
      back: 'grey_check_cloth',
    },
    availability: {
      man: 'prod-80200621',
      woman: 'prod-80206711',
    },
    configuration: {
      nodes: {
        gems: false,
        ribbon_g: true,
      },
      materials: {
        rubbers: '#242424',
        sole: '#242424',
        stitches: '#7b7b7b',
        strap1: '#7b7b7b',
        strap2: '#7b7b7b',
        strap5: '#7b7b7b',
        strap6: '#7b7b7b',
        inside: '#842d2b',
        laces: {
          color: undefined,
          texture: 'laces_white.jpg',
        },
        logo: {
          color: undefined,
          texture: 'burberry_bw.jpg',
        },
        strap3: {
          AlbedoPBR: {
            color: '#1b5a90',
          },
          BumpMap: {
            enable: false,
          },
          CavityPBR: {
            enable: false,
          },
        },
        strap4: {
          AlbedoPBR: {
            color: '#1b5a90',
          },
          BumpMap: {
            enable: false,
          },
          CavityPBR: {
            enable: false,
          },
        },
        tongue: {
          AlbedoPBR: {
            texture: undefined,
            color: '#7b7b7b',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
        sides_01: {
          AlbedoPBR: {
            texture: undefined,
            color: '#7b7b7b',
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            texCoordUnit: 3,
            texture: 'suede_bump.jpg',
            enable: true,
            factor: 10,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        sides_02: {
          AlbedoPBR: {
            texture: undefined,
            color: '#7b7b7b',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        main_front: {
          AlbedoPBR: {
            texture: undefined,
            color: '#7b7b7b',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        main_back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#842D2B',
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            enable: false,
          },
          RoughnessPBR: {
            factor: 0.6,
          },
        },
        back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#7b7b7b',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
      },
    },
    buttonColors: ['#E62E22', '#8c8b8c'],
  },
  {
    name: 'White/Red',
    active: true,
    code: 'c3',
    materialsReplacement: {
      ribbon: 'fake_mat4',
      rubber_top: 'red_simple_rubber',
      rubber_cap: 'red_cap_rubber',
      rubber_rombes: 'red_rombes_rubber',
      rubber_rough: 'red_noise_rubber',
      rubber_stripes: 'red_lines_rubber',
      sole: 'red_simple_rubber',
      logo: 'fake_mat6',
      logo2: 'logo_red',
      stitches: 'grey_stitches',
      stitches2: 'grey_stitches',
      strap1: 'white_strap',
      strap2: 'grey_stripe',
      strap3: 'white_strap',
      strap4: 'grey_stripe',
      strap5: 'grey_stripe',
      strap6: 'white_strap_gloss',
      inside: 'grey_satin',
      laces: 'white_laces',
      tongue: 'white_check_cloth',
      sides_01: 'white_cloth',
      sides_02: 'white_cloth',
      main_front: 'white_check_cloth',
      main_back: 'white_check_cloth',
      back: 'white_check_cloth',
    },
    availability: {
      man: 'prod-80200961',
      woman: 'prod-80210361',
    },
    configuration: {
      nodes: {
        gems: false,
        ribbon_g: true,
      },
      materials: {
        rubbers: '#842d2b',
        sole: '#842d2b',
        stitches: '#a6a6a6',
        strap1: '#a6a6a6',
        strap2: '#898989',
        strap5: '#898989',
        strap6: '#a6a6a6',
        inside: '#717171',
        laces: {
          color: undefined,
          texture: 'laces_grey.jpg',
        },
        logo: {
          color: undefined,
          texture: 'burberry_rw.jpg',
        },
        strap3: {
          AlbedoPBR: {
            color: '#a6a6a6',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        strap4: {
          AlbedoPBR: {
            color: '#898989',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        tongue: {
          AlbedoPBR: {
            texture: undefined,
            color: '#A6A6A6',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
        sides_01: {
          AlbedoPBR: {
            texture: undefined,
            color: '#A6A6A6',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            factor: 1,
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        sides_02: {
          AlbedoPBR: {
            texture: undefined,
            color: '#A6A6A6',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        main_front: {
          AlbedoPBR: {
            texture: undefined,
            color: '#A6A6A6',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
        main_back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#A6A6A6',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#A6A6A6',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
      },
    },
    buttonColors: ['#fff', '#e62e22'],
  },
  {
    name: 'Black',
    active: true,
    code: 'c6',
    materialsReplacement: {
      ribbon: 'fake_mat1',
      rubber_top: 'black_simple_rubber',
      rubber_cap: 'black_cap_rubber',
      rubber_rombes: 'black_rombes_rubber',
      rubber_rough: 'black_noise_rubber',
      rubber_stripes: 'black_lines_rubber',
      sole: 'black_simple_rubber',
      logo: 'logo_bw',
      logo2: 'logo_black',
      stitches: 'black_stitches',
      stitches2: 'black_stitches',
      strap1: 'black_strap',
      strap2: 'black_stripe',
      strap3: 'red_strap',
      strap4: 'white_stripe',
      strap5: 'black_stripe',
      strap6: 'black_strap_gloss',
      inside: 'black_satin',
      laces: 'black_laces',
      tongue: 'black_cloth',
      sides_01: 'black_suede',
      sides_02: 'black_cloth',
      main_front: 'black_cloth',
      main_back: 'black_satin',
      back: 'black_check_cloth',
    },
    availability: {
      man: 'prod-80200891',
      woman: false,
    },
    configuration: {
      nodes: {
        gems: false,
        ribbon_g: true,
      },
      materials: {
        rubbers: '#242424',
        sole: '#242424',
        stitches: '#242424',
        strap1: '#242424',
        strap2: '#242424',
        strap5: '#242424',
        strap6: '#242424',
        inside: '#242424',
        laces: {
          color: undefined,
          texture: 'laces_black.jpg',
        },
        logo: {
          color: undefined,
          texture: 'burberry_bw.jpg',
        },
        strap3: {
          AlbedoPBR: {
            color: '#842d2b',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        strap4: {
          AlbedoPBR: {
            color: '#a6a6a6',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        tongue: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        sides_01: {
          AlbedoPBR: {
            texture: undefined,
            color: '#161616',
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            enable: false,
          },
          RoughnessPBR: {
            factor: 0.6,
          },
        },
        sides_02: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        main_front: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        main_back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            enable: false,
          },
          RoughnessPBR: {
            factor: 0.6,
          },
        },
        back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
      },
    },
    buttonColors: ['#000'],
  },
  {
    name: 'Archive Beige',
    active: true,
    code: 'c5',
    materialsReplacement: {
      ribbon: 'fake_mat1',
      rubber_top: 'black_simple_rubber',
      rubber_cap: 'black_cap_rubber',
      rubber_rombes: 'black_rombes_rubber',
      rubber_rough: 'black_noise_rubber',
      rubber_stripes: 'black_lines_rubber',
      sole: 'black_simple_rubber',
      logo: 'logo_bw',
      logo2: 'logo_black',
      stitches: 'red_stitches',
      stitches2: 'black_stitches',
      strap1: 'black_strap',
      strap2: 'black_stripe',
      strap3: 'black_strap',
      strap4: 'white_stripe',
      strap5: 'white_stripe',
      strap6: 'black_strap_gloss',
      inside: 'black_satin',
      laces: 'black_laces',
      tongue: 'checker_cloth',
      sides_01: 'red_cloth',
      sides_02: 'black_suede',
      main_front: 'checker_cloth',
      main_back: 'checker_cloth',
      back: 'black_check_cloth',
    },
    availability: {
      man: 'prod-80217721',
      woman: 'prod-80206801',
    },
    configuration: {
      nodes: {
        gems: false,
        ribbon_g: true,
      },
      materials: {
        rubbers: '#242424',
        sole: '#242424',
        stitches: '#842D2B',
        strap1: '#242424',
        strap2: '#242424',
        strap5: '#a6a6a6',
        strap6: '#242424',
        inside: '#242424',
        laces: {
          color: undefined,
          texture: 'laces_black.jpg',
        },
        logo: {
          color: undefined,
          texture: 'burberry_bw.jpg',
        },
        strap3: {
          AlbedoPBR: {
            color: '#242424',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        strap4: {
          AlbedoPBR: {
            color: '#a6a6a6',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        tongue: {
          AlbedoPBR: {
            texCoordUnit: 3,
            texture: 'checker.jpg',
            color: undefined,
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        sides_01: {
          AlbedoPBR: {
            texture: undefined,
            color: '#842D2B',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            factor: 1,
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        sides_02: {
          AlbedoPBR: {
            texture: undefined,
            color: '#161616',
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            enable: false,
          },
          RoughnessPBR: {
            factor: 0.6,
          },
        },
        main_front: {
          AlbedoPBR: {
            texCoordUnit: 3,
            texture: 'checker.jpg',
            color: undefined,
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        main_back: {
          AlbedoPBR: {
            texCoordUnit: 3,
            texture: 'checker.jpg',
            color: undefined,
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
      },
    },
    buttonColors: ['#AE8D6C'],
  },
  {
    name: 'Black/Crystal',
    active: true,
    code: 'c2',
    materialsReplacement: {
      rubber_top: 'black_simple_rubber',
      rubber_cap: 'black_cap_rubber',
      rubber_rombes: 'black_rombes_rubber',
      rubber_rough: 'black_noise_rubber',
      rubber_stripes: 'black_lines_rubber',
      sole: 'black_simple_rubber',
      logo: 'logo_bw',
      logo2: 'logo_black',
      stitches: 'black_stitches',
      stitches2: 'black_stitches',
      strap1: 'black_strap',
      strap2: 'black_stripe',
      strap3: 'black_strap',
      strap4: 'black_stripe',
      strap5: 'black_stripe',
      strap6: 'black_strap_gloss',
      inside: 'black_satin',
      laces: 'black_laces',
      tongue: 'black_cloth',
      sides_01: 'black_suede',
      sides_02: 'black_cloth',
      main_front: 'black_cloth',
      main_back: 'black_satin',
      back: 'black_check_cloth',
    },
    availability: {
      man: false,
      woman: 'prod-80210901',
    },
    configuration: {
      nodes: {
        gems: true,
        ribbon_g: false,
      },
      materials: {
        rubbers: '#242424',
        sole: '#242424',
        stitches: '#242424',
        strap1: '#242424',
        strap2: '#242424',
        strap5: '#242424',
        strap6: '#242424',
        inside: '#242424',
        laces: {
          color: undefined,
          texture: 'laces_black.jpg',
        },
        logo: {
          color: undefined,
          texture: 'burberry_bw.jpg',
        },
        strap3: {
          AlbedoPBR: {
            color: '#242424',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        strap4: {
          AlbedoPBR: {
            color: '#242424',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        tongue: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        sides_01: {
          AlbedoPBR: {
            texture: undefined,
            color: '#161616',
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            enable: false,
          },
          RoughnessPBR: {
            factor: 0.6,
          },
        },
        sides_02: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        main_front: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
        },
        main_back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            enable: false,
          },
          RoughnessPBR: {
            factor: 0.6,
          },
        },
        back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
      },
    },
    buttonColors: ['#000'],
  },
  {
    name: 'Red',
    active: true,
    code: 'c7',
    materialsReplacement: {
      ribbon: 'fake_mat3',
      rubber_top: 'red_simple_rubber',
      rubber_cap: 'red_cap_rubber',
      rubber_rombes: 'red_rombes_rubber',
      rubber_rough: 'red_noise_rubber',
      rubber_stripes: 'red_lines_rubber',
      sole: 'black_simple_rubber',
      logo: 'logo_rw',
      logo2: 'logo_black',
      stitches: 'red_stitches',
      stitches2: 'red_stitches',
      strap1: 'red_strap',
      strap2: 'red_stripe',
      strap3: 'red_strap',
      strap4: 'white_stripe',
      strap5: 'white_stripe',
      strap6: 'red_strap_gloss',
      inside: 'black_satin',
      laces: 'red_laces',
      tongue: 'red_check_cloth',
      sides_01: 'red_cloth',
      sides_02: 'red_cloth',
      main_front: 'red_cloth',
      main_back: 'red_satin',
      back: 'red_check_cloth',
    },
    availability: {
      man: 'prod-80200911',
      woman: false,
    },
    configuration: {
      nodes: {
        gems: false,
        ribbon_g: true,
      },
      materials: {
        rubbers: '#842d2b',
        sole: '#242424',
        stitches: '#842d2b',
        strap1: '#842d2b',
        strap2: '#8b403e',
        strap5: '#898989',
        strap6: '#8b403e',
        inside: '#242424',
        laces: {
          color: undefined,
          texture: 'laces_red.jpg',
        },
        logo: {
          color: undefined,
          texture: 'burberry_rw.jpg',
        },
        strap3: {
          AlbedoPBR: {
            color: '#842d2b',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        strap4: {
          AlbedoPBR: {
            color: '#898989',
          },
          BumpMap: {
            enable: true,
          },
          CavityPBR: {
            enable: true,
          },
        },
        tongue: {
          AlbedoPBR: {
            texture: undefined,
            color: '#842D2B',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
        sides_01: {
          AlbedoPBR: {
            texture: undefined,
            color: '#842D2B',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
            factor: 1,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        sides_02: {
          AlbedoPBR: {
            texture: undefined,
            color: '#842d2b',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'cloth_1_bump.jpg',
            enable: true,
          },
          RoughnessPBR: {
            factor: 0.24,
          },
        },
        main_front: {
          AlbedoPBR: {
            texture: undefined,
            color: '#842D2B',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
            factor: 0.15,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
        main_back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#242424',
          },
          CavityPBR: {
            enable: false,
          },
          BumpMap: {
            enable: false,
          },
          RoughnessPBR: {
            factor: 0.6,
          },
        },
        back: {
          AlbedoPBR: {
            texture: undefined,
            color: '#842d2b',
          },
          CavityPBR: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
          BumpMap: {
            texCoordUnit: 1,
            texture: 'checker_bump.jpg',
            enable: true,
          },
        },
      },
    },
    buttonColors: ['#E62E22'],
  },
]

export default schemes
