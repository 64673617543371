import { srgbToLinear, hexToRgb } from './colors.helper'
const changeSneaker = ({ scheme, apiSn, apiGl }) =>
  new Promise((res) => {
    apiSn.setCurrentAnimationByUID(apiSn.animations.filter((a) => a[1] === 'configure')[0][0])
    apiSn.play()

    // apiGl.setCurrentAnimationByUID(apiGl.animations.filter((a) => a[1] === 'configure')[0][0])
    // apiGl.play()

    // start main scene transformations
    setTimeout(() => updateMaterials({ scheme, apiSn }), 500)

    setTimeout(() => {
      apiSn.pause()
      apiSn.seekTo(0)
      // apiGl.pause()
      // apiGl.seekTo(0)
      res()
    }, 1300)
  })

const updateMaterials = ({ scheme, apiSn }) => {
  const { materialsReplacement, configuration } = scheme
  const { nodes } = configuration
  // Show/hide nodes
  Object.keys(nodes).forEach((n) => {
    if (nodes[n]) {
      apiSn.node_show(n)
    } else {
      apiSn.node_hide(n)
    }
  })
  Object.keys(materialsReplacement).forEach((m) => {
    const defaultMaterial = { ...apiSn._getMaterialByName(m) }
    const newMaterial = apiSn._getMaterialByName(materialsReplacement[m])
    if (newMaterial) defaultMaterial.channels = newMaterial.channels
    apiSn.setMaterial(defaultMaterial)
  })
}

const updateMaterials_old = ({ scheme, apiSn }) => {
  const { nodes, materials } = scheme.configuration
  // Show/hide nodes
  Object.keys(nodes).forEach((n) => {
    if (nodes[n]) {
      apiSn.node_show(n)
    } else {
      apiSn.node_hide(n)
    }
  })

  const rubbersMaterials = [
    'rubber_rough',
    'rubber_rombes',
    'rubber_stripes',
    'rubber_top',
    'rubber_shadow',
    'rubber_cap',
  ]
  rubbersMaterials.forEach((mat) => {
    // Here only albedo color
    const linearColor = srgbToLinear(hexToRgb(materials.rubbers))
    const defaultMaterial = { ...apiSn._getMaterialByName(mat) }
    defaultMaterial.channels.AlbedoPBR = {
      ...defaultMaterial.channels.AlbedoPBR,
      color: linearColor,
    }
    apiSn.setMaterial(defaultMaterial)
  })
  const albedoOnlyMats = ['sole', 'stitches', 'strap1', 'strap2', 'strap5', 'strap6', 'inside']
  albedoOnlyMats.forEach((mat) => {
    // Here only albedo color
    const linearColor = srgbToLinear(hexToRgb(materials[mat]))
    const defaultMaterial = { ...apiSn._getMaterialByName(mat) }
    defaultMaterial.channels.AlbedoPBR = {
      ...defaultMaterial.channels.AlbedoPBR,
      color: linearColor,
    }
    apiSn.setMaterial(defaultMaterial)
  })

  const albedoColorTexture = ['laces', 'logo']
  albedoColorTexture.forEach((mat) => {
    // Here albedo color/texture
    if (materials[mat].color) {
      const linearColor = srgbToLinear(hexToRgb(materials[mat].color))
      const defaultMaterial = { ...apiSn._getMaterialByName(mat) }
      defaultMaterial.channels.AlbedoPBR = {
        ...defaultMaterial.channels.AlbedoPBR,
        color: linearColor,
        texture: undefined, // ?
      }
      apiSn.setMaterial(defaultMaterial)
    } else if (materials[mat].texture) {
      const texture = (apiSn.textures.filter((t) => t.name === materials[mat].texture) || [])[0]
      const defaultMaterial = { ...apiSn._getMaterialByName(mat) }
      defaultMaterial.channels.AlbedoPBR = {
        ...defaultMaterial.channels.AlbedoPBR,
        color: undefined,
        texture: {
          uid: texture.uid,
        },
      }
      apiSn.setMaterial(defaultMaterial)
    }
  })

  const albedoAndBump = ['strap3', 'strap4']
  albedoAndBump.forEach((mat) => {
    // Here albedo color & bump on/off
    const linearColor = srgbToLinear(hexToRgb(materials[mat].AlbedoPBR.color))
    const bumpMapState = materials[mat].BumpMap.enable
    const cavityState = materials[mat].CavityPBR.enable
    const defaultMaterial = { ...apiSn._getMaterialByName(mat) }
    defaultMaterial.channels.AlbedoPBR = {
      ...defaultMaterial.channels.AlbedoPBR,
      color: linearColor,
    }
    defaultMaterial.BumpMap = {
      ...defaultMaterial.channels.BumpMap,
      enable: bumpMapState,
    }
    defaultMaterial.CavityPBR = {
      ...defaultMaterial.channels.CavityPBR,
      enable: cavityState,
    }
    apiSn.setMaterial(defaultMaterial)
  })

  const albedoCavityBump = ['tongue', 'sides_01', 'sides_02', 'main_front', 'main_back', 'back']
  albedoCavityBump.forEach((mat) => {
    const material = materials[mat]
    const { AlbedoPBR, CavityPBR, BumpMap, RoughnessPBR } = material
    const cavityState = CavityPBR.enable
    const bumpMapState = BumpMap.enable
    const defaultMaterial = { ...apiSn._getMaterialByName(mat) }

    if (AlbedoPBR.color) {
      const linearColor = srgbToLinear(hexToRgb(AlbedoPBR.color))
      defaultMaterial.channels.AlbedoPBR = {
        ...defaultMaterial.channels.AlbedoPBR,
        color: linearColor,
        texture: undefined,
      }
    } else if (AlbedoPBR.texture) {
      const texture = (apiSn.textures.filter((t) => t.name === AlbedoPBR.texture) || [])[0]
      defaultMaterial.channels.AlbedoPBR = {
        ...defaultMaterial.channels.AlbedoPBR,
        color: undefined,
        texture: {
          texCoordUnit: AlbedoPBR.texCoordUnit || defaultMaterial.channels.AlbedoPBR.texCoordUnit,
          uid: texture.uid,
        },
      }
    }

    if (cavityState) {
      const texture = (apiSn.textures.filter((t) => t.name === CavityPBR.texture) || [])[0]
      defaultMaterial.channels.CavityPBR = {
        ...defaultMaterial.channels.CavityPBR,
        enable: true,
        factor: CavityPBR.factor || defaultMaterial.channels.CavityPBR.factor,
        texture: {
          ...defaultMaterial.channels.CavityPBR.texture,
          texCoordUnit: CavityPBR.texCoordUnit || defaultMaterial.channels.CavityPBR.texCoordUnit,
          uid: texture.uid,
        },
      }
    } else {
      defaultMaterial.channels.CavityPBR = {
        ...defaultMaterial.channels.CavityPBR,
        enable: false,
      }
    }

    if (bumpMapState) {
      const bumpTexture = (apiSn.textures.filter((t) => t.name === BumpMap.texture) || [])[0]
      defaultMaterial.channels.BumpMap = {
        ...defaultMaterial.channels.BumpMap,
        enable: true,
        factor: BumpMap.factor || defaultMaterial.channels.BumpMap.factor,
        texture: {
          ...defaultMaterial.channels.BumpMap.texture,
          texCoordUnit: BumpMap.texCoordUnit || defaultMaterial.channels.BumpMap.texCoordUnit,
          uid: bumpTexture.uid,
        },
      }
    } else {
      defaultMaterial.channels.BumpMap = {
        ...defaultMaterial.channels.BumpMap,
        enable: false,
      }
    }

    if (RoughnessPBR) {
      defaultMaterial.channels.RoughnessPBR = {
        ...defaultMaterial.channels.RoughnessPBR,
        factor: RoughnessPBR.factor,
      }
    }

    apiSn.setMaterial(defaultMaterial)
  })
}

export { changeSneaker, updateMaterials }
